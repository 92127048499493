import Layout from "../../components/layout";
import PageTitle from "../../components/pagetitle";
import Seo from "../../components/seo";
import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";

const AnnapolisPage = () => (
    <Layout>
        <PageTitle title="Managed Backup Services in Maryland" page="PageTitleAbout" />
        <Seo title="Managed Backup Services in Maryland"
             description="New Vertical offers Managed Backups and backup monitoring services in Maryland. New Vertical offers many services centered around backups and keeping backups safe."
             pathname={"/technology-services/managed-backups/"} />
        <div className="content-page-wrapper">
            <div className="page-intro">
                <StaticImage
                    style={{
                        gridArea: "1/1",
                    }}
                    layout="fullWidth"
                    loading="eager"
                    aspectRatio={3 / 1}
                    alt="Our team will help"
                    src={
                        "../../images/backups.jpg"
                    }
                    formats={["auto", "jpg", "avif"]}
                />
                <h2>Managed Backup Services in Maryland</h2>

                <p>New Vertical Technologies and the engineers who work for New Vertical are experts at managing backups. There is no better way to prepare for a system failure or compromise than by managing backups. </p>
                <p>Backup management does not need to be difficult or expensive, though it must certainly be managed. There are many tools for commercial, enterprise, or personal that can be used to ensure backups are being kept safe.
                </p>
                <h2>Managed Backups Include</h2>
                <p>

                    <ul>
                        <li><b>Backup Integrity:</b>  Backups can get corrupted. Corruption means that through the process of writing the data to a storage location, some bytes fail to write or the location that the data is written to is bad.
                            Bad data means that the data stored in those locations are forever lost. Data can contain check-sums that work to check if there has been data corruption, though will not do anything if that corruption has occurred. Therefore, it is necessary that the data should be replaced either on working hardware or with data that does not contain errors.
                            This is a simple problem to solve, though can be a major problem if data corruption exists at the time when the backup is needed.</li>
                        <li><b>Backup Increments:</b>  Backups should be taken as often as possible. Then means at a minimum daily. However, taking full backups daily can result in a significant portion of data. Consider if the amount of data backed up is 100GB. This means that if the data is kept for a period of 1 year, daily backups will need 36,500GB or 36 Terabytes of space. Luckily, algorithms were written that perform incremental backups. This means that only the data saved for that day is stored.
                            On average less than 1% of data changes per day. This means that over the same year, only roughly 400GB will be needed to ensure backups are managed.</li>
                        <li><b>Data Retention:</b>  The next item to consider is the length of time backups are stored. Data is only valid for a certain lifetime. This is the same with backups. Backup retention time is calculated as a tradeoff based on how long to keep the backup vs how long it would take to restore from the backup. Based on the example above, if a restore needed to happen on the last day of the year, then all 365 incremental restores would need to be applied in order from the time the initial full backup is made. This can result in significant time and expense. the upside is that the system can be restored at any time in that 365-day period.
                            The drawback is that could take days to restore and if any one of the incremental backups is corrupt, then that would be the last time the backup could be restored.
                        </li>
                        <li><b>Data Recovery:</b>  Generally, we choose the best tradeoff between incremental and full backup data sets. Restoring 7 or at most 30 incremental backups is possible. This means that after that time period a new full backup need to be taken. Taking a full backup means that all incremental backups can be removed during that time period. However, then the problem of storage size returns based on how long to store the full backups. A good rule is that have as many full backups as an incremental time period.
                            This means that from the example above there would be 7 full backups and at most 7 incremental backups. In the above 100GB example, then there would be 700GB used + 7GB for incremental </li>
                        <li><b>Data Security:</b>  Backup security is also important as compromised backups or stolen data is critical to keep fully managed. Many backups are password protected or signed with keys to ensure integrity. Also, data can be encrypted at rest. These measures are good at keeping the risk low that even if data backups fell into the wrong hands, they would be proven useless. Another good measure is regular security control audits.
                            Audits will ensure there are no easy back doors that bad guys can use to access the data without becoming aware of the intent. </li>
                    </ul>
                    <br/>
                    <h2>Contact Us for Managed Backups</h2>
                    <p>Backup management is generally something left to companies with policies and procedures in place that can ensure backups are ready and available when needed. </p>
                </p>
            </div>
        </div>
    </Layout>
)

export default AnnapolisPage